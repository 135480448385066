import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class AirfreightApicallsService {
  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  getBusinessEntity = () => localStorage.getItem('namcvalue');

  getBusinessEntityName = () => localStorage.getItem('namcName');

  getUserRole = () => localStorage.getItem('UserRoles');

  getUserUPN = () => localStorage.getItem('UPN');

  getWorkID = () => localStorage.getItem('workdayId');

  getUserName = () => localStorage.getItem('UserName');
  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: this.getToken(),
      //'X-Api-Key': this.environment.airOrderKey,
    });
  };

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        //'X-Api-Key': this.environment.airOrderKey,
      }),
    };
  };

  httpCustomOptions1 = () => {
    return {
      params: {
        business_entity: this.getBusinessEntity(),
        user_role: this.getUserRole(),
        workid: this.getWorkID(),
      },
      headers: this.getApiHeaders(),
    };
  };

  apiUrl = '';

  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) {
    this.apiUrl = environment.airOrderUrl;
  }
  app;

  getAirOrderData(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/getairfreight-data`, data, this.httpCustomOptions());
  }
  addAirOrderDetails(data): Observable<any> {
 return this.http.post<any>(`${this.apiUrl}/add-air-order`, data, this.httpCustomOptions());
//return this.http.post('http://localhost:3000/addair',data)
  }
  editAirOrderDetails(data): Observable<any> {
   return this.http.put<any>(`${this.apiUrl}/edit-air-order`, data, this.httpCustomOptions());
    //return this.http.post('http://localhost:3000/edit-air-order', data);
  }
  getPartNumber(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getordered-parts`, this.httpCustomOptions1());
  }
  getCalendar(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getCalendar`, this.httpCustomOptions1());
    //return this.http.post('http://localhost:3000/addair',data)
    //return this.http.get('http://localhost:3000/getCalendar',this.httpCustomOptions1())
  }
  getKanban(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getordered-kanban`, this.httpCustomOptions1());
  }
  getDock(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getordered-dock`, this.httpCustomOptions1());
  }
  getDestCode(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getordered-destcodes`, this.httpCustomOptions1());
  }
  getStatus(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getstatus`, this.httpCustomOptions1());
  }
  // getDeleteAirorder(data): Observable<any> {
  //   return this.http.delete<any>(`${this.apiUrl}/deleteAirorder`,data, this.httpCustomOptions());
  // }
  getDeleteAirorder(data): Observable<any> {
    const httpCustomOptions = {
      body: data,
      headers: this.getApiHeaders(),
    };

    return this.http.delete<any>(
      `${this.apiUrl}/deleteAirorder`,

      httpCustomOptions
    );
  }
  getReason(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getReasonCode`, this.httpCustomOptions1());
  }
  getBudgetCodes(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getbudgetcode`, this.httpCustomOptions1());
  }
  getBudgetCodesDefault(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getDefaultBudgetCode`, this.httpCustomOptions1());
  }

  getManagerDetails(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/manager-details`, this.httpCustomOptions1());
  }

  getAirCaseCodes(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getaircasecode`, this.httpCustomOptions1());
  }
  getConfigDock(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getConfigDock`, this.httpCustomOptions1());
  }

  getAirfreightAirCaseCodes(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/airfreighAirCaseCode`, this.httpCustomOptions1());
  }


  getKvcDetails(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/kvc-details`, this.httpCustomOptions1());
  }
  getDeleteSchedule(data): Observable<any> {
    const httpCustomOptions = {
      body: data,
      headers: this.getApiHeaders(),
    };

    
    return this.http.delete<any>(
      `${this.apiUrl}/deleteShedule`,


      httpCustomOptions
    );
  }
  getAirCostData(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getaircost`, this.httpCustomOptions1());
  }
  editAirCost(data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/editaircost`, data, this.httpCustomOptions());
  }
  editAddAirCaseCode(data): Observable<any> {
    // return this.http.put('http://localhost:3000/delete',data)

    return this.http.put<any>(`${this.apiUrl}/addAircaseCode`, data, this.httpCustomOptions());
  }

  addBudgetcode(data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/addbudgetcode`, data,this.httpCustomOptions());
    //return this.http.put('http://localhost:3000/delete',data)
  }
  getContactName(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getcontactname`, this.httpCustomOptions1());
  }
  getContactRole(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getcontactrole`, this.httpCustomOptions1());
  }
  getDeleteContact(data): Observable<any> {
    const httpCustomOptions = {
      body: data,
      headers: this.getApiHeaders(),
    };

    
    return this.http.delete<any>(
      `${this.apiUrl}/deleteContact`,

      httpCustomOptions
    );
  }
  getDeleteBudgetCode(data): Observable<any> {
    const httpCustomOptions = {
      body: data,
      headers: this.getApiHeaders(),
    };

    
    return this.http.delete<any>(
      `${this.apiUrl}/deleteBudgetCode`,

      httpCustomOptions
    );
  }

  addContact(data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/addAirContact`, data,this.httpCustomOptions());
    //return this.http.put('http://localhost:3000/delete',data)
  }

  getCreatedManagerDetails(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/created-manager-name`, this.httpCustomOptions1());
  }

  getCreatedKvcDetails(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/created-kvc-name`, this.httpCustomOptions1());
  }

  downloadAirfreightFile(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/downloadtemplate`,
      data,
      this.httpCustomOptions()
    );
  }
  setdefaultBudgetCode(data): Observable<any> {
    // return this.http.post('http://localhost:3000/delete',data)

    return this.http.put<any>(`${this.apiUrl}/updateDefaultBudgetCode`, data, this.httpCustomOptions());
  }
  setdefaultAirCode(data): Observable<any> {
    // return this.http.put('http://localhost:3000/delete',data)

    return this.http.put<any>(`${this.apiUrl}/updateDefaultAirCode`, data, this.httpCustomOptions());
  }

  deletAirCaseCode(data): Observable<any> {
    const httpCustomOptions = {
      body: data,
      headers: this.getApiHeaders(),
    };

    
    return this.http.delete<any>(
      `${this.apiUrl}/deleteAirCaseCode`,

      httpCustomOptions
    );
  }

  updateEtatime(data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/updateEtaTime`, data,this.httpCustomOptions());
    // return this.http.put('http://localhost:3000/delete',data)
  }
  getAirfreightBudgetCode(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getairBudgetcode`, this.httpCustomOptions1());
  }
  getDataforAirOrderNo(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/air-order-no`, data, this.httpCustomOptions());
  }

}