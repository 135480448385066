import { ActivatedRoute } from '@angular/router';
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription, forkJoin } from 'rxjs';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from '../services/excel.service';
import { OrderExcelServiceService } from '../../app/order-excel-service.service';
import { GriddataService } from '../services/griddata.service';
import { filter } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

import { OSPStateService } from '../services/osp-state.service';
import { dateFilterComparator, resetSortingAndFilters, warningMessage, successMessage } from '../constants';
import { logErrors } from '../shared/logger';
import * as mm from 'moment-timezone';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { AirFreightActionsComponent } from './air-freight-actions/air-freight-actions.component';
import { OspService } from '../core/master-services/osp.service';

import { RowNode, GroupCellRendererParams, RowGroupingDisplayType } from 'ag-grid-community';
import { AirfreightApicallsService } from '../services/air-freight-validation/airfreight-apicalls.service';
import { LookupService } from '../services/lookup/lookup.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ValidationService } from '../services/air-freight-validation/validations.service';
import { DataServiceService } from '../services/air-freight-validation/data-service.service';
import { AirorderDeleteComponent } from './delete-airorder/airorder-delete.component';
import { AirOrderCellRendererComponent } from './air-order-cell-renderer/air-order-cell-renderer.component';

@Component({
  selector: 'app-air-freight-order',
  templateUrl: './air-freight-order.component.html',
  styleUrls: ['./air-freight-order.component.scss'],
})
export class AirFreightOrderComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  count = 0;
  rowData = [];
  originalRowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  private isInitialized = false;
  columnDefsMaster;
  columnDefs;
  public defaultColDef;
  rowSelection;
  user: string;
  userRole: any;
  warningMessage = '';
  offset: number = 0;
  dataCount: number = 0;
  successMessage = '';
  canceledRecord: any = [];
  isShowArrived: boolean = false;
  isShowCanceled: boolean = false;
  isNavigatedFromSecond: boolean = false; // Flag to track navigation source
  autoGroupColumnDef;
  groupRowsSticky;
  groupDefaultExpanded = 1;
  showOpenedGroup = false;
  groupSelectsChildren;
  suppressRowClickSelection;
  suppressAggFuncInHeader;
  public groupDisplayType: RowGroupingDisplayType = 'singleColumn';
  dataFromSecond: any;
  private orderDataSubscription: Subscription;
  private orderEditDataSubscription: Subscription;

  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  onInitVanningFrom: any;
  onInitVanningTo: any;
  headerCheckboxChecked: boolean = false;
  partDesc = 'Part Description';

  editEnabled = false;
  freezeStatus = false;
  filter = false;
  showToggleAll = false;
  showClear = true;
  disablePartNumber: boolean;
  disableKanban: boolean;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedStatus: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  adjList: any = [];
  selectedAdjlist: any = [];
  addAirOrderPart: any = [];
  addAirOrdereKanban: any = [];
  selectedAircaseCode: any = [];

  vanningFrom: any;
  vanningTo: any;
  adjustSubscription$: Subscription;

  // Dropdown Options
  ordDate: any;
  ordDateTo: any;
  onInitordDate: any;
  onIntordDateTo: any;

  kanbanList: any = [];
  partNoList: any = [];
  destCodeList: any = [];
  containerList: any = [];
  statusList: any = [];
  dockList: any = [];
  specialistList: any = [];
  lifecycleList: any = [];
  rowCount = 0;
  count1 = 0;
  isEditMode = false;
  modalComponent = 'modal-component';
  addGetResponse = [];
  minVanningFrom;
  showHideList: any = [
    // {
    //   label: 'Air Order No',
    //   value: 'air_order_no',
    // },
    {
      label: 'Dock',
      value: 'dock',
    },
    {
      label: 'Air Case Code',
      value: 'air_case_code',
    },
    {
      label: 'Part Number',
      value: 'part_no',
    },

    {
      label: 'Kanban',
      value: 'kanban',
    },
    {
      label: 'Arrival DT',
      value: 'arrival_date',
    },
    {
      label: 'No Of Pieces',
      value: 'number_of_pieces',
    },
    {
      label: 'No Of Lots',
      value: 'number_of_lots',
    },

    {
      label: 'Dest Code',
      value: 'dest_code',
    },
    {
      label: 'Status',
      value: 'air_order_status',
    },
    {
      label: 'Van Center Confirmed',
      value: 'kvc_conf',
    },
    {
      label: 'Van Center ETA DT',
      value: 'vanning_date',
    },
    {
      label: 'Renban',
      value: 'case_no',
    },
    {
      label: 'Order DT',
      value: 'date_raised',
    },
    { value: 'userid', label: 'User' },
    { value: 'date_time', label: 'Updated DT' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;

  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;
  orderAdded: any;
  gridApi: any;
  pendingOrderData: any;
  pendingOrderEditData: any;
  itContact: string;
  airCaseCode: any = [];

  constructor(
    private readonly spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly airFreightService: AirfreightApicallsService,
    private readonly lookupservice: LookupService,
    private router: Router,
    private validationService: ValidationService,
    private dataService: DataServiceService,
    private orderExcelService: OrderExcelServiceService,
    private route: ActivatedRoute
  ) {
    this.context = {
      componentParent: this,
    };

    // this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
    //   const currentNavigation = this.router.getCurrentNavigation();
    //   if (currentNavigation?.extras.state) {
    //     this.dataFromSecond = currentNavigation.extras.state.data;
    //     this.isNavigatedFromSecond = true; // Set the flag when data is received
    //     console.log('Received data:', this.dataFromSecond);
    //     this.handleOrderAdded(this.dataFromSecond);
    //   } else {
    //     console.log('No navigation state available');
    //   }
    // });
  }
  numberComparator(number1, number2) {
    return number1 - number2;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
    if (this.orderDataSubscription) {
      this.orderDataSubscription.unsubscribe();
    }
    if (this.orderEditDataSubscription) {
      this.orderEditDataSubscription.unsubscribe();
    }
  }

  getUrlParams(key: string) {
    const params = {};
    const queryString = window.location.search.substring(1);
    const regex = /([^&=]+)=([^&]*)/g;
    let match;

    while ((match = regex.exec(queryString))) {
      params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
    }

    return params[key];
  }
  removeURLParam() {
    const url = new URL(window.location.href);
    url.search = '';
    history.pushState({}, '', url.toString());
  }
  push_history() {
    var localstorage_key = this.getUrlParams('keyParam');
    if (localstorage_key != undefined && localstorage_key != null && localstorage_key != '') {
      const userData = localStorage.getItem(localstorage_key);
      const userObject = JSON.parse(userData);
      // console.log(userObject, 'temp_ userdetails');
      if (userObject != null && userObject !== undefined) {
        if (userObject.date_raised_from != undefined) this.ordDate = userObject.date_raised_from;
        if (userObject.date_raised_to != undefined) this.ordDateTo = userObject.date_raised_to;
        // select dest_code
        if (userObject.dest_code != undefined)
          userObject.dest_code.forEach((e_) => {
            this.selectedDestCode.push({ dest_code: e_ + '' });
          });
        // select dock
        if (userObject.dock != undefined)
          userObject.dock.forEach((e_) => {
            this.selectedDock.push({ dock: e_ });
          });
        // select kanban
        if (userObject.kanban != undefined)
          userObject.kanban.forEach((e_) => {
            this.selectedKanban.push({ kanban: e_ });
          });
        // select air case code
        if (userObject.air_case_code != undefined)
          // this.set_aircase_value(userObject);
          userObject.air_case_code.forEach((e_) => {
            this.selectedAircaseCode.push({ air_case_code: e_ + '' });
          });
        //select part no
        if (userObject.part_no != undefined)
          userObject.part_no.forEach((e_) => {
            this.selectedPartNo.push({ part_no: e_ });
          });
        //select status
        if (userObject.status != undefined)
          userObject.status.forEach((e_) => {
            this.selectedStatus.push({ air_order_status: e_ });
          });

        this.callSearch(userObject);
        localStorage.removeItem(localstorage_key);
      }
      // userObject.dock.forEach(e_ => {
      //   this.selectedDock.push({'dock':e_});
      // });
    }
    this.removeURLParam();
  }
  ngOnInit(): void {
    // this.push_history();
    // this.orderCreatedDownloadButton();
    console.log('ngoninit called');
    // let isClearMsg = localStorage.getItem('isClearMsg');

    this.pendingOrderData = null;
    this.pendingOrderEditData = null;
    // let isAdd= localStorage.getItem('isadd');
    // if(isAdd){
    //   console.log('true..........i')
    // }
    // else{
    //   console.log('false...............i')
    // }

    // this.validationService.orderData$.subscribe((orderData) => {
    //   if (orderData) {
    //     this.pendingOrderData = orderData; // Store until init is complete
    //   }
    // });

    // //editorderDataSubject

    // this.validationService.editedData$.subscribe((orderEditData) => {
    //   if (orderEditData) {
    //     this.pendingOrderEditData = orderEditData; // Store until init is complete
    //   }
    // });

    this.orderDataSubscription = this.validationService.orderData$.subscribe((orderData) => {
      if (orderData) {
        this.pendingOrderData = orderData; // Store until init is complete
      }
    });

    //editorderDataSubject

    this.orderEditDataSubscription = this.validationService.editedData$.subscribe((orderEditData) => {
      if (orderEditData) {
        this.pendingOrderEditData = orderEditData; // Store until init is complete
      }
    });
    this.initializeGridColumns();
    this.adjustSubscription$ = this.stateService.getNamc().subscribe(() => {
      this.initializeData();

      // Retrieve `isAdd` from localStorage

      const isAdd = localStorage.getItem('isAdd') === 'true'; // Convert string to boolean
      console.log(isAdd, this.pendingOrderData, this.pendingOrderEditData, 'checks...');

      if (isAdd && this.pendingOrderData) {
        console.log('isAdd is true, calling handleOrderAdded');
        this.handleOrderAdded(this.pendingOrderData);

        this.pendingOrderData = null; // Reset
      }

      if (!isAdd && this.pendingOrderEditData) {
        console.log('isAdd is false, calling handleOrderEdited');
        this.handleOrderEdited(this.pendingOrderEditData);

        this.pendingOrderEditData = null; // Reset
      }
      this.push_history();
    });
  }

  createColumnRendererForField(fieldName: string) {
    return {
      // Optimized valueGetter to avoid unnecessary operations
      valueGetter: (params) => {
        if (params.node.group) {
          // For group rows, return the value of the first child for the given field
          const firstChildData = params.node.allLeafChildren?.[0]?.data;
          if (firstChildData) {
            return firstChildData[fieldName];
          }
          return ''; // If no data, return empty string
        }
        // For child rows, return the value directly
        return params.data?.[fieldName] || ''; // Return empty string if field is not available
      },

      // Optimized cellRenderer: hide the value for group rows and show for child rows
      cellRenderer: (params) => {
        if (params.node.group) {
          return ''; // Instead of returning null, return an empty string
        }
        // For child rows, show the value of the field
        return params.value || ''; // Show the value for child rows
      },
    };
  }

  private initializeGridColumns() {
    this.columnDefsMaster = [
      {
        headerName: '',
        cellRendererFramework: AirFreightActionsComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        field: 'actions',
        width: 80,
        sortable: false,
        floatingFilter: true,
        pinned: 'left',
        cellRendererParams: {
          // Pass a parameter to the component to determine if it should render
          showIcon: (params) => params.node.group, // Show icon only if it's not a group
        },
      },
      {
        headerName: 'Air Order No',
        field: 'air_order_no',
        width: 170,
        floatingFilter: true, // Enable floating filter
        tooltipField: 'air_order_no',
        headerTooltip: 'Air Order No',
        rowGroup: true, // Grouping by air_order_no
        hide: true, // Ensure it's not hidden for filtering
        checkboxSelection: (params) => params.node.group,
        headerCheckboxSelection: true,
        cellRendererFramework: AirOrderCellRendererComponent, // Use the custom cell renderer for all rows
        filter: 'agTextColumnFilter', // Text filter for air_order_no
        floatingFilterComponentParams: {
          suppressFilterButton: true, // Optionally suppress filter button in the floating filter
        },
        valueGetter: (params) => {
          // For group rows, show the air_order_no of the first child
          if (params.node.group) {
            const firstData = params.node.allLeafChildren[0]?.data; // Get the first child data in the group
            return firstData ? firstData.air_order_no : ''; // Return air_order_no from the first child or empty string
          }
          // For child rows, return air_order_no for the floating filter (do not display)
          return params.data?.air_order_no || ''; // Still return it for floating filter (non-empty string)
        },
      },

      {
        headerName: 'Dock',
        field: 'dock',
        width: 80,
        floatingFilter: true,
        tooltipField: 'dock',
        headerTooltip: 'Dock',
        sortable: true,
        ...this.createColumnRendererForField('dock'),
      },
      {
        headerName: 'Air Case Code',
        field: 'air_case_code',
        width: 100,
        floatingFilter: true,
        tooltipField: 'air_case_code',
        headerTooltip: 'Air case Code',
        ...this.createColumnRendererForField('air_case_code'),
      },

      {
        headerName: 'Part No',
        field: 'part_no',
        width: 130,
        floatingFilter: true,
        tooltipField: 'part_no',
        headerTooltip: 'Part Number',
        ...this.createColumnRendererForField('part_no'),
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        width: 90,
        floatingFilter: true,
        headerTooltip: 'Kanban',
        tooltipField: 'Kanban',
        ...this.createColumnRendererForField('kanban'),
      },
      {
        headerName: 'Arrival DT',
        field: 'arrival_date',
        headerTooltip: 'Arrival Date',
        width: 120,
        floatingFilter: true,
        supressMenu: true,
        tooltipField: 'Arrival Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        ...this.createColumnRendererForField('arrival_date'),
      },
      {
        headerName: 'No Of Pieces',
        field: 'number_of_pieces',
        headerTooltip: 'Number Of Pieces',
        sortable: true,
        width: 80,
        floatingFilter: true,
        tooltipField: 'pieces_to_order',
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        ...this.createColumnRendererForField('number_of_pieces'),
      },
      {
        headerName: 'No Of Lots',
        headerTooltip: 'Number Of Lots',
        field: 'number_of_lots',
        sortable: true,
        width: 80,
        floatingFilter: true,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        ...this.createColumnRendererForField('number_of_lots'),
      },
      {
        headerName: 'Dest Code',
        field: 'dest_code',
        sortable: true,
        width: 80,
        floatingFilter: true,
        headerTooltip: 'Dest Code',
        ...this.createColumnRendererForField('dest_code'),
      },
      {
        headerName: 'Status',
        field: 'air_order_status',
        sortable: true,
        width: 130,
        floatingFilter: true,
        tooltipField: 'air_order_status',
        headerTooltip: 'Status',
        ...this.createColumnRendererForField('air_order_status'),
      },

      {
        headerName: 'Van Center Confirmed',
        field: 'kvc_conf',
        width: 110,
        floatingFilter: true,
        headerTooltip: 'Van Center Confirmed',
        ...this.createColumnRendererForField('kvc_conf'),
      },

      {
        headerName: 'Van Center ETA DT',
        field: 'eta_date',
        width: 120,
        headerTooltip: 'Van Center ETA Date(mm/dd/yyyy)',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        ...this.createColumnRendererForField('eta_date'),
      },
      {
        headerName: 'Renban',
        field: 'case_no',
        width: 90,
        sorting: true,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        headerTooltip: 'Renban',
        filter: 'agTextColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        ...this.createColumnRendererForField('case_no'),
      },
      {
        headerName: 'Order DT',
        field: 'date_raised',
        width: 130,
        floatingFilter: true,
        headerTooltip: 'Order Date(mm/dd/yyyy)',
        headerClass: 'ag-theme-custom-text-right',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        ...this.createColumnRendererForField('date_raised'),
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 150,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        ...this.createColumnRendererForField('userid'),
      },
      {
        headerName: 'Updated DT',
        field: 'date_time',
        width: 150,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        ...this.createColumnRendererForField('date_time'),
      },
    ];

    this.columnDefs = [...this.columnDefsMaster];

    this.autoGroupColumnDef = {
      headerName: 'Air Order No',
      field: 'air_order_no',
      width: 170,
      floatingFilter: true, // Enable floating filter
      tooltipField: 'air_order_no',
      headerTooltip: 'Air Order No',
      rowGroup: true, // Grouping by air_order_no
      hide: true, // Ensure it's not hidden for filtering
      checkboxSelection: (params) => params.node.group,
      headerCheckboxSelection: true,
      cellRendererFramework: AirOrderCellRendererComponent, // Use the custom cell renderer for all rows
      filter: 'agTextColumnFilter', // Text filter for air_order_no
      floatingFilterComponentParams: {
        suppressFilterButton: true, // Optionally suppress filter button in the floating filter
      },
      valueGetter: (params) => {
        // For group rows, show the air_order_no of the first child
        if (params.node.group) {
          const firstData = params.node.allLeafChildren[0]?.data; // Get the first child data in the group
          return firstData ? firstData.air_order_no : ''; // Return air_order_no from the first child or empty string
        }
        // For child rows, return air_order_no for the floating filter (do not display)
        return params.data?.air_order_no || ''; // Still return it for floating filter (non-empty string)
      },
    };

    this.rowSelection = 'multiple';
    this.groupSelectsChildren = true;
    this.suppressRowClickSelection = true;
    this.suppressAggFuncInHeader = true;
    this.editType = 'fullRow';
    this.showOpenedGroup = true;
    this.groupRowsSticky = true;

    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = (params) => {
      this.gridApi = params.api;
    };
  }

  initializeData() {
    console.log('initialized data ');
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    this.itContact = localStorage.getItem('itContact');

    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    if (!this.isNavigatedFromSecond) {
      console.log('navigation if');
      // Clear message if not navigated from second
      this.removeMessage();
    }

    this.loadDrop();
    this.onResetDropDown();

    this.originalRowData = [...this.rowData]; // Keep a copy of the original data

    this.setPageSizeToAll();
  }
  checkIfAnySelected() {
    if (this.selectedDestCode.length != 0) {
      return true;
    }

    return false;
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onSearch(offset) {
    this.removeMessage();
    this.spinner.show();
    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }
    // if (!this.isNavigatedFromSecond) {
    //   console.log('navigation if');
    //   // Clear message if not navigated from second
    //   this.removeMessage();
    // }

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
      this.offset = 0;
      this.spinner.show();
    }
    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectdest;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    this.callSearch(this.getSearchData(offset));
  }
  getSearchData(offset) {
    let kanban;
    let selectedPartNo;
    let selectedDestCode;
    let selectedContainer;
    let selectedStatus;
    let selectedDock;
    let selectedSpecialist;
    let selecteAdjlist;
    let selectedAdjlist;
    let selectedAircaseCode;
    console.log(this.kanbanList, this.selectedKanban, 'temp_kanban list');
    if (this.selectedKanban.length > 0) {
      if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        kanban = [];
        this.selectedKanban.forEach((element) => {
          kanban.push(element.kanban);
        });
      }
    }
    console.log(this.airCaseCode, this.selectedAircaseCode, 'temp_aircase code list');
    if (this.selectedAircaseCode.length > 0) {
      if (!this.selectedAircaseCode.some((a) => a.air_case_code === 'ALL')) {
        selectedAircaseCode = [];
        this.selectedAircaseCode.forEach((element) => {
          selectedAircaseCode.push(element.air_case_code);
        });
      }
    }
    console.log(this.partNoList, this.selectedPartNo, 'temp_part list');
    if (this.selectedPartNo.length > 0) {
      if (!this.selectedPartNo.some((a) => a.part_no === 'ALL')) {
        selectedPartNo = [];
        this.selectedPartNo.forEach((element) => {
          selectedPartNo.push(element.part_no);
        });
      }
    }
    console.log(this.destCodeList, this.selectedDestCode, 'temp_destCodeList list');
    if (this.selectedDestCode.length > 0) {
      if (!this.selectedDestCode.some((a) => a.dest_code === 'ALL')) {
        selectedDestCode = [];
        this.selectedDestCode.forEach((element) => {
          selectedDestCode.push(element.dest_code);
        });
      }
    }
    if (this.selectedContainer.length > 0) {
      if (!this.selectedContainer.some((a) => a.cont_code === 'ALL')) {
        selectedContainer = [];
        this.selectedContainer.forEach((element) => {
          selectedContainer.push(element.cont_code);
        });
      }
    }

    if (this.selectedStatus.length > 0) {
      if (!this.selectedStatus.some((a) => a.air_order_status === 'ALL')) {
        selectedStatus = [];
        this.selectedStatus.forEach((element) => {
          selectedStatus.push(element.air_order_status);
        });
      }
    }

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }

    if (this.selectedSpecialist.length > 0) {
      if (!this.selectedSpecialist.some((a) => a.EMPLOYEE === 'ALL')) {
        selectedSpecialist = [];
        this.selectedSpecialist.forEach((element) => {
          selectedSpecialist.push(element.EMPLOYEE);
        });
      }
    }

    if (this.selectedAdjlist.length > 0) {
      if (!this.selectedAdjlist.some((a) => a.adj_type === 'ALL')) {
        selectedAdjlist = [];
        this.selectedAdjlist.forEach((element) => {
          selectedAdjlist.push(element.adj_type);
        });
      }
    }

    return {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      kanban: kanban,
      part_no: selectedPartNo,
      dest_code: selectedDestCode,
      dock: selectedDock,
      date_raised_from: this.ordDate,
      date_raised_to: this.ordDateTo,
      air_case_code: selectedAircaseCode,
      status: selectedStatus,
    };
  }
  callSearch(data) {
    this.spinner.show();
    console.log(data, 'temp_ data console after call');
    this.airFreightService.getAirOrderData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body.data) {
          this.rowData.push(...res.body.data.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

          this.dataCount = parseInt(res.body.data.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  loadDrop() {
    const lookupSubscriptions = [
      this.airFreightService.getPartNumber(),
      this.airFreightService.getKanban(),
      this.airFreightService.getDock(),
      this.airFreightService.getDestCode(),
      this.airFreightService.getStatus(),
      this.lookupservice.getUserShowHideColumns(),
      this.airFreightService.getAirfreightAirCaseCodes(),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanbanList, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.destCodeList = [];
        this.containerList = [];
        this.statusList = [];
        this.dockList = [];
        this.adjList = [];
        this.specialistList = [];
        this.airCaseCode = [];
        if (res[1].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.kanbanList.push(...res[1].body.data);

        if (res[0].body.data) {
          this.partNoList.push({ part_no: 'ALL' });
        }
        this.partNoList.push(...res[0].body.data);

        if (res[2].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        this.dockList.push(...res[2].body.data);

        if (res[3].body.data) {
          this.destCodeList.push({ dest_code: 'ALL' });
        }
        this.destCodeList.push(...res[3].body.data);
        this.selectedDestCode = [{ dest_code: 'ALL' }];

        if (res[4].body.data) {
          this.statusList.push({ air_order_status: 'ALL' });
          this.selectedStatus = res[4].body.data.filter(
            (order) => order.air_order_status === 'CONFIRMED' || order.air_order_status === 'CREATED'
          );
        }
        this.statusList.push(...res[4].body.data);

        const { air_order_columns } = res[5].body.showHideColumnsResponse;

        if (res[6].body.data) {
          this.airCaseCode.push({ air_case_code: 'ALL' });
        }
        this.airCaseCode.push(...res[6].body.data);
        this.airCaseCode = this.airCaseCode.filter(
          (value, index, self) => index === self.findIndex((t) => t.air_case_code === value.air_case_code)
        );

        try {
          const hideItems = JSON.parse(air_order_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        // this.onInitVanningTo = this.vanningTo;
        this.spinner.hide();
      },
      error: this.errorCallback,
    });

    //   },
    // });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    // this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  onResetDropDown() {
    if (!this.isNavigatedFromSecond) {
      console.log('navigation if');
      // Clear message if not navigated from second
      this.removeMessage();
    }
    this.selectedDestCode = [{ dest_code: 'ALL' }];
    this.selectedKanban = [];
    this.selectedPartNo = [];
    // this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedStatus = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedAdjlist = [];
    this.selectedLifecycle = [];
    this.ordDate = this.onInitordDate;
    this.ordDateTo = this.onInitordDate;

    this.kanbanSearchValue = null;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }

  air_order_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) return;

    this.air_order_columns = hideItems;
    if (!hideItems.length) this.air_order_columns = [{}];
  }

  saveShowHide() {
    this.spinner.show();

    let air_order_columns = this.air_order_columns;
    if (air_order_columns) {
      this.lookupservice.updateUserShowHideColumns({ air_order_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (this.selectedPartNo[this.selectedPartNo.length - 1].part_no === 'ALL') {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex((data) => data.part_no === 'ALL');

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }

    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
  }

  onDestCodeChange(): void {
    if (this.selectedDestCode.length >= 1) {
      if (this.selectedDestCode[this.selectedDestCode.length - 1].dest_code === 'ALL') {
        this.selectedDestCode = [];
        this.selectedDestCode.push(this.destCodeList[0]);
      } else {
        let indexAll = this.selectedDestCode.findIndex((data) => data.dest_code === 'ALL');

        if (indexAll > -1) {
          this.selectedDestCode.splice(indexAll, 1);
        }
      }
    }
  }

  statusChange(): void {
    if (this.selectedStatus.length >= 1) {
      if (this.selectedStatus[this.selectedStatus.length - 1].air_order_status === 'ALL') {
        this.selectedStatus = [];
        this.selectedStatus.push(this.statusList[0]);
      } else {
        let indexAll = this.selectedStatus.findIndex((data) => data.air_order_status === 'ALL');

        if (indexAll > -1) {
          this.selectedStatus.splice(indexAll, 1);
        }
      }
    }
  }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }
  airCaseCodeChange(): void {
    if (this.selectedAircaseCode.length >= 1) {
      if (this.selectedAircaseCode[this.selectedAircaseCode.length - 1].air_case_code === 'ALL') {
        this.selectedAircaseCode = [];
        this.selectedAircaseCode.push(this.airCaseCode[0]);
      } else {
        let indexAll = this.selectedAircaseCode.findIndex((data) => data.air_case_code === 'ALL');

        if (indexAll > -1) {
          this.selectedAircaseCode.splice(indexAll, 1);
        }
      }
    }
  }
  adjChange(): void {
    if (this.selectedAdjlist.length >= 1) {
      if (this.selectedAdjlist[this.selectedAdjlist.length - 1].adj_type === 'ALL') {
        this.selectedAdjlist = [];
        this.selectedAdjlist.push(this.adjList[0]);
      } else {
        let indexAll = this.selectedAdjlist.findIndex((data) => data.adj_type === 'ALL');

        if (indexAll > -1) {
          this.selectedAdjlist.splice(indexAll, 1);
        }
      }
    }
  }

  specialistChange(): void {
    if (this.selectedSpecialist.length >= 1) {
      if (this.selectedSpecialist[this.selectedSpecialist.length - 1].EMPLOYEE === 'ALL') {
        this.selectedSpecialist = [];
        this.selectedSpecialist.push(this.specialistList[0]);
      } else {
        let indexAll = this.selectedSpecialist.findIndex((data) => data.EMPLOYEE === 'ALL');

        if (indexAll > -1) {
          this.selectedSpecialist.splice(indexAll, 1);
        }
      }
    }
  }

  getModifiedRowData(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
    this.orderData = '';
  }

  addNew() {
    this.updateURLAndNavigate();
    this.router.navigate(['add-air-order']);
  }

  // handleOrderAdded(orderData: any) {
  //   this.onSearch(0)

  //   // console.log('handle order', orderData);

  //   // this.successMessage = 'hi';

  //   // console.log(orderData.body.addResponse);
  //   // if (orderData.statusCode == '200') {
  //   //   this.onSearch(0)
  //   //   this.successMessage = `Order Created! ${orderData.body.addResponse}`;
  //   // }
  //   // this.isNavigatedFromSecond = false;
  // }
  orderData: any;
  neworderNo :any;
  handleOrderAdded(orderData: any) {
    this.orderData = orderData[0].air_order_no;
    // this.successMessage = `Order Created! ${orderData.body.addResponse.updatedRows.data[0].air_order_no}`;
    console.log('handle ordere add called', orderData);
    let isClearMsg = localStorage.getItem('isClearMsg');
    if (isClearMsg == 'false') {
      this.successMessage = `Order Created! ${orderData[0].air_order_no}`;
      this.orderData = `${orderData[0].air_order_no}`;
      this.rowData = orderData;
      this.osPartsGrid?.gridOptions?.api.setRowData(this.rowData);
      this.setPageSizeToAll();
      this.pendingOrderData = {}; // Reset
      localStorage.setItem('isClearMsg', 'true');
      this.spinner.hide();
    }
    this.pendingOrderData = {}; // Reset
    // localStorage.setItem('isClearMsg','true');
  }
  //editorderDataSubject

  orderCreatedDownloadButton() {
    this.orderExcelService.generateExcel(this.rowData);
  }

  handleOrderEdited(orderEditData: any) {
    console.log('Handling order edited with data:', orderEditData);
    // console.log(orderEditData.body?.data?.air_order_no,)
    let isClearMsg = localStorage.getItem('isClearMsg');
    if (isClearMsg == 'false') {
      this.successMessage = `Order Edited ${orderEditData?.body?.data?.updatedRows?.data[0]?.air_order_no}`;
      this.orderData = `${orderEditData?.body?.data?.updatedRows?.data[0]?.air_order_no}`;
      this.rowData = orderEditData?.body?.data?.updatedRows?.data;
      this.osPartsGrid?.gridOptions?.api.setRowData(this.rowData);

      this.pendingOrderEditData = {}; // Reset
      this.setPageSizeToAll();
      localStorage.setItem('isClearMsg', 'true');
    }

    this.pendingOrderEditData = {}; // Reset
    localStorage.setItem('isClearMsg', 'true');
    //this.successMessage = `Order Edited! ${orderEditData.body.addResponse[0]}`;
  }

  getSelectedRowData(): any {
    // Get the selected rows using the grid API
    const selectedNodes = this.gridOptions.api.getSelectedNodes();

    // Map the selected nodes to their data
    const selectedRowData = selectedNodes.map((node) => node.data);

    console.log(selectedRowData, 'selected row data...');

    return selectedRowData;
  }

  onEditMode(): void {
    this.removeMessage();
    let data = [];
    data = this.getSelectedRowData();

    console.log(data, 'data.......');

    // console.log(data, 'editcheck');
    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 100);
    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;
      this.isEditMode = false;
      this.editEnabled = false;
      window.scroll(0, 0);
    }
  }

  updateURLAndNavigate() {
    const key: any = 'keyParam';
    const value: string = new Date().getTime() + '';
    localStorage.setItem(value, JSON.stringify(this.getSearchData(0)));
    const url = new URL(window.location.href);
    url.searchParams.append(key, value);
    history.pushState(null, '', url.toString());
  }

  userCheckChanged(air_order_no) {
    let res1: any = [];
    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      air_order_no: air_order_no,
    };
  
    this.airFreightService.getDataforAirOrderNo(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body.data) {
          console.log(res.body.data.data);
          res1.push(...res.body.data.data);
          console.log(res1, 'teacher');
  
          // Send filtered data to service
          this.dataService.sendFilteredData(res1);
  
          // Now that the data is updated, navigate to the next screen
          this.updateURLAndNavigate();
          this.router.navigate(['edit-air-order']);
        } else {
          this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
          this.rowData = [];
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.spinner.hide();
        }
      },
    });
  
    
  }
  
  userDeleteChanged(rowIndex) {
    let childRows = this.getChildRowsForParent(rowIndex);
    console.log(childRows);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '200px';
    dialogConfig.width = '460px';
    dialogConfig.data = childRows;

    const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);
    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      data: childRows,
    };
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        this.airFreightService.getDeleteAirorder(data).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.data.table1DeletedCount >= 1 || data.body.data.table2DeletedCount >= 1) {
              this.onSearch(0);
              this.successMessage = successMessage.deleteRecord;
              this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });
      }
    });

    // next: (res) => {
    //   console.log(res, 'getair');
    //   if (res.body.data) {
    //     console.log(res)
    //     this.spinner.hide();
    //          } else {
    //           this.spinner.hide();
    //         }

    //     this.setPageSizeToAll();

    // },
  }

  // Function to get child rows for a given parent row
  getChildRowsForParent(rowIndex: number) {
   

    // Get the parent rowNode using the rowIndex
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    console.log(rowNode, 'node..');

    if (rowNode && rowNode.group) {
      // Ensure the parent row is expanded (just in case it is collapsed)
      if (!rowNode.expanded) {
        rowNode.setExpanded(true); // Expand the parent to make sure children are accessible
      }

      // Now get the child rows after expanding
      const childRows = rowNode.childrenAfterGroup; // This will be the child rows associated with this parent

      // Extract only the 'data' from each child row
      const childData = childRows.map((childRowNode) => childRowNode.data);

      // Return the array of child data
      return childData;
    } else {
      console.log('This is not a parent row or the rowNode could not be found.');
      return []; // Return empty array if not a valid parent row
    }
  }

  exportAsXLSX(): void {
    if (!this.isNavigatedFromSecond) {
      console.log('navigation if');
      this.removeMessage(); // Clear message if not navigated from second
    }

    this.spinner.show();

    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);

    let selectedData: any[] = [];

    // Collect selected rows from the grid
    console.log('header checkbox checked.......');
    this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
      if (!node.group && node.isSelected()) {
        selectedData.push(node.data);
      }
    });

    // Check if any rows are selected
    const selectedRows = this.osPartsGrid.gridOptions.api.getSelectedNodes();

    console.log(selectedData, 'selected data............');

    // If no rows are selected, show warning message
    if (selectedRows.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;
      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        const exportData: any[] = [];

        selectedData.forEach((e) => {
          const obj = {
            'Air Order No': e.air_order_no,
            Dock: e.dock,
            'Air Case Code': e.air_case_code,
            'Part No': e.part_no,
            Kanban: e.kanban,
            'Arrival DT': e.arrival_date,
            'No Of Pieces': e.number_of_pieces,
            'No Of Lots': e.number_of_lots,
            'Dest Code': e.dest_code,
            Status: e.air_order_status,
            'Van Center Confirmed': e.kvc_conf,
            'Van Center ETA DT': e.eta_date,
            Renban: e.case_no,
            'Order DT': e.date_raised,
            User: e.userid,
            'Updated DT': e.date_time,
          };
          exportData.push(obj);
        });

        const headers = [
          'Air Order No',
          'Dock',
          'Air Case Code',
          'Part No',
          'Kanban',
          'Arrival DT',
          'No Of Pieces',
          'No Of Lots',
          'Dest Code',
          'Status',
          'Van Center Confirmed',
          'Van Center ETA DT',
          'Renban',
          'Order DT',
          'User',
          'Updated DT',
        ];

        // Map of English headers to Japanese translations
        // const translations = {
        //   'Air Order No': '航空注文番号',
        //   Dock: 'ドック',
        //   'Air Case Code': '航空ケースコード',
        //   'Part No': '部品番号',
        //   Kanban: 'かんばん',
        //   'Arrival DT': '到着日',
        //   'No Of Pieces': '個数',
        //   'No Of Lots': 'ロット数',
        //   'Dest Code': '目的地コード',
        //   Status: 'ステータス',
        //   'Van Center Confirmed': 'バンセンター確認済み',
        //   'Van Center ETA DT': 'バンセンター到着予定日',
        //   Renban: '連番',
        //   'Order DT': '注文日',
        //   User: 'ユーザー',
        //   'Updated DT': '更新日',
        // };

        // Create the second row with Japanese translations
        //const japaneseRow = headers.map((header) => translations[header]);

        // Add the headers and translations as the first two rows
        const finalExportData = exportData.map((item) => headers.map((header) => item[header]));

        const timestamp = easternCurrentDate
          .split('.')[0]
          .replace('T', '_')
          .split('')
          .filter((d) => d !== '-' && d !== ':')
          .join('');

        this.excelService.exportAsExcelFileAirfreight(
          finalExportData, // Final data including headers and translations
          'Airfreight_order' + timestamp, // Filename with timestamp
          headers // Pass headers
        );

        this.spinner.hide();
      }, 1000);
    }
  }

  getdownloadTemplate() {
    console.log('hi....................');
    this.removeMessage();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      file_name: 'airfreight_order.xlsx',
    };

    this.airFreightService.downloadAirfreightFile(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res) {
          console.log(res, 'res.....');
          const a = document.createElement('a');
          a.href = res.body.data;
          a.target = '_blank';
          a.rel = 'noreferrer noopener';

          if (a) {
            window.location.href = a.href;
          }

          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.warningMessage = warningMessage.fileNotAvilable;
        }
      },
    });
  }
  handleAirOrderClick(airorder_no: any) {
    this.orderExcelService.generateExcel(this.rowData.filter((employee) => employee.air_order_no === airorder_no));
  }
}
